export default async () => {
  const { init, replayIntegration, browserTracingIntegration } = await import(
    '@sentry/vue'
  )

  init({
    dsn: 'https://fccdf903fac996884d5f1e6ead0e8942@o4508714976215040.ingest.de.sentry.io/4508716501106768',
    tracePropagationTargets: [
      // /^https:\/\/staging\.airlst\.app/,
      /^https:\/\/airlst\.app/,
    ],
    integrations: [browserTracingIntegration(), replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.01,
    // Session Replay
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.05,
  })
}
